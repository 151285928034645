[class*="addVisitor"] {
  input::placeholder, textarea {
    color: #bdbdbd;
    font-size: 12px;
  }
select {
  font-size: 12px;
  color: #bdbdbd;
  option{
    font-size: 16px;
    color: #212529;
  }
}
input, .ant-picker, select {
  height: 37px;
  display: block;
}
}