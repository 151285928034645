@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --primary-font: 'Poppins', Arial, Helvetica, sans-serif;
  --secondary-font: 'Outfit', Arial, Helvetica, sans-serif;
}

body {
  margin: 0;
  font-family: var(--secondary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212529;
}

section {
  &.page-content-wrapper {
    min-height: calc(100vh - 100px);
    padding-top: 75px;
  }
}

code {
  font-family: 'poppins';
}

a {
  text-decoration: none;
  color: #0078d4;

  &:hover {
    text-decoration: underline;
    color: #0468b5;
  }
}

p {
  font-size: 16px;
}

.h-37 {
  height: 37.6px !important;
}

img {
  width: 100%;
}

p {
  &.error-msg {
    color: #f63649 !important;
    font-size: 14px !important;
    margin-bottom: 0 !important;
    font-weight: 400 !important;
    margin-top: 2px !important;
    position: absolute;
  }
}

.ant-picker-input {
  input {
    font-size: 16px;
  }
}

label {
  &.error {
    & + input {
      border-color: #ff0000 !important;
    }

    & + select {
      border-color: #ff0000;
    }

    & + div {
      .rmdp-input {
        border-color: #ff0000;
      }
    }

    & + textarea {
      border-color: #ff0000;
    }

    & + .ant-picker {
      border-color: #ff0000;
    }

    & + div {
      .ant-picker {
        border-color: #ff0000;
      }
    }

    & + .fileUploadSec {
      .fileUploadWrapper {
        .fileUpload {
          label {
            border-color: #ff0000;
          }
        }
      }
    }
  }
}

.add-poll-reccuring-date {
  .ant-form-item-explain-error {
    color: #f63649;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 400;
    margin-top: 2px;
    position: absolute;
    left: 0;
    font-family: 'Outfit';
  }

  .reccuring-field-wrapper {
    .ant-space {
      .ant-form-item-has-error {
        input {
          border-color: #ff0000;
        }
      }

      .reccuring-field-row {
        input {
          border-radius: 0.375rem;
          font-size: 16px;
        }
      }
    }
  }
}

.btn {
  &.btn-casa {
    font-size: 1rem;
    line-height: 1.5rem;
    background-color: #FCBB55;
    padding: 0.875rem 2.5rem;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    border: 0;
    border-radius: 32px;
    color: #fff;
    text-wrap: nowrap;

    &:hover {
      background-color: #f1ab39;
      color: #fff;
    }
  }
}

.productCategories {

  &.mlAuto {
    margin-left: auto;
  }

  .dropdown {

    .toggle {
      background-color: #fff;
      color: #212529;
      font-size: .875rem;
      line-height: 1.25rem;
      border: 1px solid #e5e7eb;
      // margin-right: 2px;
      padding-top: .5rem;
      padding-bottom: .5rem;
      padding-left: 1rem;
      padding-right: 3rem;
      border-radius: 9999px;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      width: 100%;
      text-align: left;
      position: relative;

      .categoriesIcon {
        font-size: 20px;
        margin-right: 0.2rem;
        color: #454545;
      }

      &:focus {
        z-index: 3;
        color: #0a58ca;
        background-color: #f9f9f9;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
      }

      &::after {
        position: absolute;
        right: 17px;
        top: 17px;
      }
    }

    .menu {
      border: 1px solid #e5e7eb;
      border-radius: 1rem;
      margin-top: .75rem;
      width: auto;
      min-width: 350px !important;
      padding: 0rem;
      z-index: 3;
      box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 8px 10px -6px rgba(0, 0, 0, .1);

      .categoryForm {
        padding-bottom: 76px;
        min-height: 422px;
      }

      .dropdownMenu {
        max-height: 350px;
        overflow: auto;
      }

      .item {
        margin: 0.8rem 1.5rem;
        display: flex;
        justify-content: left;
        align-items: center;
        font-size: 13px;
        // border: 1px solid #e5e7eb;
      }

      .dropdownBtn {
        background-color: #eee;
        padding: 1.25rem;
        display: flex;
        justify-content: space-between;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        position: absolute;
        top: 345px;
        width: 100%;

        .bthClear {
          background: #fff;
          color: #212529;
          border: 1px solid #e5e7eb;
          border-radius: 25px;
          padding: 0.5rem 1.5rem;
          -webkit-box-shadow: 0px 5px 8px 0px rgba(153, 153, 153, 1);
          -moz-box-shadow: 0px 5px 8px 0px rgba(153, 153, 153, 1);
          box-shadow: 0px 5px 8px 0px rgba(153, 153, 153, 1);
        }

        .bthApply {
          background: #000000;
          color: #fff;
          border-radius: 25px;
          padding: 0.5rem 1.5rem;
          -webkit-box-shadow: 0px 5px 8px 0px rgba(153, 153, 153, 1);
          -moz-box-shadow: 0px 5px 8px 0px rgba(153, 153, 153, 1);
          box-shadow: 0px 5px 8px 0px rgba(153, 153, 153, 1);
          border: none;
        }
      }
    }
  }
}

.enrich-searchbox {
  position: relative;

  .enrich-search {
    background-color: #fff;
    color: #212529;
    font-size: 0.95rem;
    line-height: 1.25rem;
    border: 1px solid #e5e7eb;
    margin: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 2.5rem;
    padding-right: 1rem;
    border-radius: 9999px;
    width: 100%;

    &:focus {
      z-index: 3;
      // color: #0a58ca;
      background-color: #f9f9f9;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }
  }

  svg {
    position: absolute;
    left: 10px;
    top: 8px;
    font-size: 21px;
    color: #bbbbbb;
  }
}


@media (max-width: 600px) {
  h2 {
    font-size: 2rem !important;
    padding: 0 !important;

    span {
      font-size: 2rem !important;
    }
  }
}

@media (max-width: 360px) {
  .btn {
    &.btn-casa {
      font-size: 0.8rem;
      padding: 0.6rem 1.5rem;
    }
  }
}

input {
  :focus-visible {
    /* Draw the focus when :focus-visible is supported */
    outline: 3px solid deepskyblue;
    outline-offset: 3px;
  }
}

.ant-form-item {
  margin-bottom: 0;
}

#dynamic_form_nest_item_users_0_date {
  padding: 1.2rem;
  border-radius: 9999px;
  border: 1px solid #eee;
}

.custom-content-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  height: 100vh;

  .loader {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin: 15px auto;
    position: relative;
    color: #b2b2b2;
    box-sizing: border-box;
    animation: animloader 2s linear infinite;
  }

  @keyframes animloader {
    0% {
      box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
    }

    25% {
      box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
    }

    50% {
      box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
    }

    75% {
      box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
    }

    100% {
      box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
    }
  }
}

.ant-image-preview-wrap {
  .ant-image-preview-body {
    .ant-image-preview-img-wrapper {
      img {
        // max-width: 700px;
        width: auto;
      }
    }
  }
}

.reccuring-field-wrapper {
  .ant-space {
    max-width: 600px;
    margin-top: 1rem;
    background-color: rgba(241, 245, 249, .7);
    padding: 0.875rem;
    border-radius: 9999px;

    .reccuring-field-row {
      input {
        width: 100%;
        padding: 1.2rem;
        border-radius: 9999px;
        border: 1px solid #eee;
        height: 40px;
      }

      svg {
        cursor: pointer;
      }
    }
  }
}

.breadcrumbWrapper {
  display: block;

  .breadcrumb {
    margin: 0;
    font-size: 0.9rem;
    font-weight: 400;
    font-family: 'Barlow';

    .breadcrumb-item {
      a {
        text-decoration: none;
      }
    }
  }
}

.custom-tab-ui {
  .nav-tabs {
    .nav-item {
      margin-left: 0.35rem;

      a {
        font-family: 'Barlow';
        font-size: 0.95rem;
        padding: 0.65rem 1rem;
        color: #4d556a;
        font-weight: 500;
        border: 1px solid #dee2e6;
        box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.075);

        &:hover {
          text-decoration: none;
          color: #3f4556;
        }

        &.active {
          background-color: #4d556a;
          color: #fff;
          border-color: #4d556a;
          font-weight: 600;
        }
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .tab-content {
    .tab-pane {
      padding: 1.25rem;
      border: 1px solid #dee2e6;
      font-family: 'Barlow';
      font-size: 0.95rem;

      .stats-info {
        label {
          display: block;
          margin: 0;
          opacity: 0.85;
        }

        strong {
          font-weight: 500;
        }
      }

      .list-group {
        .list-group-item {
          padding: 0.75rem 0;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .custom-tab-ui {
    .tab-content {
      .tab-pane {
        .row {
          div[class*='col-'] {
            margin-top: 0.65rem;

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  .ant-picker-dropdown {
    .ant-picker-datetime-panel {
      flex-direction: column;
    }

    .ant-picker-header-view {
      display: none;
    }
  }

  .FittedHomeslider {
    a {
      display: flex;
      justify-content: center;
      align-content: center;

      .carousel-control-prev-icon {
        margin-left: -2rem;
        opacity: initial;
        width: 1rem;
        height: 1rem;
        background-color: #fcbb55;
        padding: 0.7rem;
        font-size: 20px;
        border-radius: 25px;
      }

      .carousel-control-next-icon {
        margin-right: -2rem;
        opacity: initial;
        width: 1rem;
        height: 1rem;
        background-color: #fcbb55;
        padding: 0.7rem;
        font-size: 20px;
        border-radius: 25px;
      }
    }
  }
}

.fileUploadSec {
  position: relative;

  .fileUploadWrapper {
    display: flex;

    .fileUpload {
      width: 100%;

      label {
        border: 1px solid #dee2e6;
        border-radius: 5px;
        width: 100%;
        text-align: left;
        background-color: #fff;
        padding: 0.55rem 0.75rem;
        color: #898c9c;
        font-size: 0.75rem !important;
        text-transform: capitalize;
        font-weight: normal !important;
        font-family: 'poppins';
      }

      input {
        display: none;
      }

      input::file-selector-button {
        background-image: linear-gradient(to right, #405189, #405189);
        background-position-x: 0%;
        background-size: 200%;
        border: none !important;
        color: #fff !important;
        padding: 0.45rem 1.25rem;
        text-shadow: 0 1px 1px #333;
        border-radius: 4px 0 0 4px;
        // transition: all 0.25s;
      }

      input::file-selector-button:hover {
        background-image: linear-gradient(to right, #273154, #273154);

        border: none !important;
      }
    }

    .numberCount {
      margin: 0 0 0 0.3rem;
      position: relative;
      top: 7px;

      .badge {
        background-color: #405189;
        height: 25px;
        width: 25px;
        border-radius: 25px;
        border: 1px solid #4f576c;
        color: #fff;
        font-weight: 600;
        font-size: 13px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-content: center;

        &:hover {
          background-color: #2d3a62;
        }
      }
    }
  }

  .badgeImages {
    background-color: #fff;
    text-align: left;
    border-radius: 5px;
    padding: 0.5rem;
    width: 70%;
    margin-left: 30%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px 4px 12px;
    position: relative;
    margin-top: 0.5rem;
    position: absolute;

    &::before {
      content: '';
      position: absolute;
      right: 8px;
      top: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #c3c3c3 transparent;
      z-index: 9999;
    }

    .badgeImagesList {
      margin: -5px 0 0 -5px;

      div {
        margin-bottom: 0;
        display: inline-block;
        max-width: 115px;
        margin: 5px 0 0 5px;

        p {
          background-color: #fafafa;
          border: 1px solid #d0cccc;
          color: rgba(0, 0, 0, 0.5490196078);
          border-radius: 25px;
          padding: 0.1rem 0.5rem;
          text-align: center;
          font-size: 10px;
          font-weight: 600;
          margin: 0;
          box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
          display: flex;
          max-width: 100%;

          span {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 80%;
          }

          button {
            background-color: transparent;
            border: none;
            padding: 0;
            margin: 0 0 0 3px;
          }

          svg {
            font-size: 17px;
            color: #de4b37;
          }
        }
      }
    }
  }
}

.ant-float-btn-group {
  right: 45px !important;
  bottom: 27px;

  .ant-float-btn-icon {
    width: 100% !important;
  }

  .ant-float-btn-circle {
    height: 60px;
    border-radius: 50%;
    width: 60px;

  }

  .ant-float-btn-body {
    background-color: #232942 !important;

    &:first-child {
      width: 100% !important;
      height: 100% !important;
    }

    svg {
      color: #fff;
      font-size: 25px;
    }
  }
}

.notificationBoardCard {
  #more {
    display: none;
  }

  #moree {
    display: none;
  }
}

.forum-chat-poll {
  ::selection {
    color: #fff;
    background: #6665ee;
  }

  .wrapper {
    width: 100%;
  }

  .wrapper header {
    font-size: 22px;
    font-weight: 600;
  }

  .wrapper .poll-area {
    margin: 20px 0 15px 0;
  }

  .poll-area label {
    display: block;
    margin-bottom: 10px;
    border-radius: 5px;
    transition: all 0.2s ease;
  }

  .poll-area label:hover {
    border-color: #ddd;
  }

  label.selected {
    border-color: #6665ee !important;
  }

  label .row {
    display: flex;
    pointer-events: none;
    justify-content: space-between;
  }

  label .row .column {
    display: flex;
    align-items: center;
  }

  label .row .circle {
    height: 19px;
    width: 19px;
    display: block;
    border: 2px solid #ccc;
    border-radius: 50%;
    margin-right: 10px;
    position: relative;
  }

  label.selected .row .circle {
    border-color: #6665ee;
  }

  label .row .circle::after {
    content: "";
    height: 11px;
    width: 11px;
    background: #6665ee;
    border-radius: inherit;
    position: absolute;
    left: 2px;
    top: 2px;
    display: none;
  }

  .poll-area label:hover .row .circle::after {
    display: block;
    background: #e6e6e6;
  }

  label.selected .row .circle::after {
    display: block;
    background: #6665ee !important;
  }

  label .row span {
    font-size: 16px;
    font-weight: 500;
  }

  label .row .percent {
    display: none;
  }

  label .progress {
    height: 16px;
    width: 100%;
    position: relative;
    background: #f0f0f0;
    margin: 8px 0 3px 0;
    border-radius: 30px;
    display: none;
    pointer-events: none;
  }

  label .progress:after {
    position: absolute;
    content: "";
    height: 100%;
    background: #ccc;
    width: calc(1% * var(--w));
    border-radius: inherit;
    transition: all 0.2s ease;
  }

  label.selected .progress::after {
    background: #6665ee;
  }

  label.selectall .progress,
  label.selectall .row .percent {
    display: block;
  }

  input[type="radio"],
  input[type="checkbox"] {
    display: none;
  }
}

span {
  &.imp-field {
    color: #f75a5a;
    font-size: 14px;
    margin-left: 2px;
  }
}

.create-option-row {
  .ant-space {
    margin-top: 0 !important;
    background-color: transparent !important;
    padding: 0 !important;
  }

  .ant-space-item {
    width: 100%;
  }

  .col-lg-5 {
    &.col-5 {
      width: 80%;
    }
  }
}

.ant-form-item {
  .ant-form-item-explain-error {
    color: #ff4d4f;
    // position: absolute;
    left: 46px;
    font-size: 11px;
    top: 38px;
  }
}

.registration-form {
  .react-datepicker-wrapper {
    width: 100%;
  }

  .checkbox {
    .form-check-input {
      height: 24px;
      width: 43px;
    }
  }
}

.ant-popover-content {
  .popoverContent {
    cursor: pointer;

    &:last-child {
      margin: 0;
    }

    &:hover {
      color: #212529;
    }
  }
}


.ant-modal-wrap {
  .ant-modal {
    .ant-modal-body {
      label {
        font-weight: 400;
        font-family: "Outfit", Arial, Helvetica, sans-serif;
      }
    }

    .ant-modal-footer {
      .ant-btn-primary {
        background: #FCBB55;
      }
    }
  }
}

.ant-popover {
  z-index: 999999;
}

.appointment {
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }

  .carousel-indicators {
    width: 50px;
    height: 50px;
    // border-radius: 25px;
  }
}

.FittedHomeslider {
  a {
    display: none;
  }

  // .carousel-control-prev {
  //     right: 118px;
  //       height: -moz-fit-content;
  //       height: fit-content;
  //       top: 270px;
  //       left: auto;
  //       display: block;
  //       width: auto;
  //       display: flex;
  //       align-items: center;
  //       border: 2px solid #9b9ea1;
  //       border-radius: 100%;
  //       width: 45px;
  //       height: 45px;
  //       background-color: #9b9ea1;
  // }
  // .carousel-control-next {
  //       right: 118px;
  //       height: -moz-fit-content;
  //       height: fit-content;
  //       top: 270px;
  //       left: auto;
  //       display: block;
  //       width: auto;
  //       display: flex;
  //       align-items: center;
  //       border: 2px solid #9b9ea1;
  //       border-radius: 100%;
  //       width: 45px;
  //       height: 45px;
  //       background-color: #9b9ea1;
  // }
  .carousel-indicators {
    bottom: -68px;

    button {
      background-color: #a5a5a5;

      &.active {
        background-color: #f0b000;
      }
    }
  }
}

.birthday-popup-modal {
  .modal-dialog {
    .modal-content {
      border: 0;
      border-radius: 1rem;

      .modal-header {
        padding: 0;
        border: 0;

        .btn-close {
          position: absolute;
          right: 11px;
          z-index: 1;
          color: #fff;
          top: 11px;
        }
      }

      .modal-body {
        padding: 0;
        border: 0;
        background-color: #212529;
        border-radius: 1rem;

        img {
          border-radius: 1rem;
        }
      }
    }
  }
}

.join-event-date-field {
  .rmdp-container {
    width: 100%;

    .rmdp-input {
      height: 37.6px;
      border: 1px solid #dee2e6;
      width: 100%;

      &:focus {
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25)
      }
    }
  }
}

.form-footer-btn {
  display: flex;
  gap: 20px;
  margin: 2.5rem 0 15px 0;
  justify-content: center;

  .btn {
    border: 0;
    border-radius: 30px;
    color: #fff;
    max-height: 33px;
    display: flex;
    align-items: center;
    padding: 7px 50px;
    text-wrap: nowrap;

    &.btn-submit {
      background: #FCBB55;

      &:hover {
        background: #efb455;
        color: #fff;
      }
    }

    &.btn-cancel {
      background: #4c5469;

      &:hover {
        background: #40485b;
        color: #fff;
      }
    }
  }
}

.form-label {
  font-weight: 500;
}

.appoinment-footer {
  .ant-modal-footer {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 2rem;

    .btn {
      border: 0;
      border-radius: 30px;
      color: #fff;
      max-height: 33px;
      display: flex;
      align-items: center;
      padding: 7px 50px;

      &.btn-submit {
        background: #FCBB55;

        &:hover {
          background: #efb455;
        }
      }

      &.btn-cancel {
        background: #4c5469;

        &:hover {
          background: #40485b;
        }
      }
    }
  }
}

::placeholder {
  color: rgb(174, 174, 174) !important;
  font-family: 'Outfit' !important;
  font-weight: 300 !important;
  font-size: 15px !important;
  opacity: 1;
  /* Firefox */
}


.popOverDeleteForum {
  p {
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: #557dff !important;
    }
  }
}

.ant-picker-dropdown {
  .ant-picker-panel-container {
    .ant-picker-panel-layout {
      .ant-picker-panel {
        .ant-picker-body {
          padding: 0px 18px;

          .ant-picker-content {
            thead {
              tr {
                th {
                  height: 30px;
                }
              }
            }

            tbody {
              tr {
                td {
                  padding: 3px 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.poll-reccuring-date-row {
  .reccuring-field-wrapper {
    .ant-space {
      margin-bottom: 22px !important;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.ant-modal-confirm {
  .ant-btn-primary {
    background-color: #FCBB55;

    &:hover {
      background-color: #efb455 !important;
    }
  }
}

.ant-modal {
  .recurring-form {
    border: 1px solid #c0c4d6;
    border-radius: 5px;
    height: 22px;
    margin: 1px 0;
    padding: 1.2rem;
  }
}

.rti--input {
  width: 100% !important;
}

.enrich-ad-carousal {
  background-color: #f8fafc;
  border-radius: 30px;
  max-height: 247px;
  padding: 25px 0 38px;
  background-size: contain;

  .carousel {
    width: 94%;
    margin: 0 auto;

    .carousel-indicators {
      bottom: -43px;

      button {
        background-color: #fbb33a !important;
      }
    }

    .carousel-item {
      .addImage {
        height: 185px;
        object-fit: cover;
        border-radius: 30px;
        max-width: 520px;
        outline: 4px solid #e9e9e9;
        margin: 4px;
      }

      .adtag {
        position: absolute;
        right: 81px;
        width: 22px;
        top: 4px;

        span {
          top: 3px;
          position: absolute;
          left: 2px;
          color: #fff;
          font-size: 13px;
        }
      }
    }

    .carousel-control-prev {
      display: none;
    }

    .carousel-control-next {
      display: none;
    }
  }
}

.choose-project-modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        padding: 14px;
        justify-content: center;
        border: 0;
        padding-bottom: 0;
        display: none;

        .modal-title {
          font-size: 19px;
        }

        .btn-close {
          display: none;
        }
      }

      .modal-body {
        padding: 21px;
      }

      .modal-footer {
        padding: 20px;
        border: 0;
        justify-content: center;
        padding-top: 0;

        .btn-primary {
          width: 54%;
        }
      }
    }
  }
}

.ant-progress {
  height: 181px !important;
  display: flex !important;
  align-items: baseline !important;
  margin-top: 20px !important;
  width: 100% !important;

  .ant-progress-inner {
    width: 180px !important;
    height: 180px !important;
    font-size: 24px !important;
    margin: 0 auto !important;
  }
}

.project-progress-card-banner {
  .milestone-carousel {
    .slick-arrow {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      /*width: 32px;
      height: 32px;
      color: #fff;
      background: #4c5469;
      border-radius: 50%;
      padding: 5px;*/
      opacity: 1;
      &::before {
        color: #4c5469;
      }
      &::after {
        display: none;
      }

      /*&::after {
        position: relative;
        top: auto;
        left: auto;
        width: 8px;
        height: 8px;
      }*/
    }

    > .slick-arrow {
      transform: none;
      top: auto;
      bottom: 25px;

      &.slick-prev {
        left: -10px;
      }

      &.slick-next {
        right: -10px;
      }
    }
  }

  .milestone-progress-carousel {
    .carousel-item {
      display: block;
      width: 100%;
      height: 200px;
      border-radius: 6px;
      cursor: pointer;
    }
  }

  .no-data {
    display: block;
    width: 100%;
    height: 200px;
    border-radius: 6px;

    img {
      height: 100%;
      width: auto;
      max-width: 100%;
      max-height: 200px;
      object-fit: contain;
      border: none !important;
      background: #fff;
    }
  }

  ul {
    margin-bottom: 0;
  }

  .image-row {
    margin: 0 auto;

    .ant-carousel {
      margin: 0;
      padding: 0;
    }

    img {
      object-fit: cover;
      height: 100%;
      border-radius: 6px;
    }
  }

  .slick-dots {
    bottom: -12px;

    li {
      height: 6px;

      &:after {
        height: 6px;
        background: #4d556a;
      }

      button {
        background: #4d556a;
        border-radius: 10px;
        height: 6px;
      }

      &.slick-active {
        &:after {
          background: #4d556a;
        }
      }
    }
  }
}

.modal {
  &.demo-modal {
    .modal-dialog {
      width: calc(100% - 30px);
      max-width: 1024px;
    }

    .modal-content {
      background: #000;
      border: solid 2px #000;
      border-radius: 6px;
    }

    .modal-header {
      padding: 0;
      border: none;
    }

    .btn-close {
      position: absolute;
      right: -10px;
      top: -10px;
      z-index: 1;
      background-color: rgba(255, 255, 255, 1);
      background-size: 12px;
      border-radius: 50%;
      opacity: 0.7;
      width: 32px;
      height: 32px;
      box-sizing: border-box;
    }

    .modal-body {
      min-height: calc(100vh - 60px);
      padding: 0;
      border-radius: 4px;
    }
  }

  &.image-modal {
    .modal-header {
      padding: 0;
      border: none;
    }

    .btn-close {
      position: absolute;
      right: -10px;
      top: -10px;
      z-index: 1;
      background-color: rgba(255, 255, 255, 1);
      background-size: 12px;
      border-radius: 50%;
      opacity: 0.7;
      width: 32px;
      height: 32px;
      box-sizing: border-box;
    }

    .modal-body {
      padding: 0;
      border-radius: 6px;
      height: calc(100vh - 80px);
    }

    .slick-slider {
      .slick-list {
        height: 100%;
      }

      .slick-track {
        height: 100%;
      }
      .slick-arrow {
        z-index: 1;
        &.slick-prev {
          left: -10px;
        }

        &.slick-next {
          right: -10px;
        }
      }
    }

    .thumbnail-carousel {
      height: 120px;
      padding: 10px 0;

      .thumbnail {
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        height: 100px;

        .img-thumbnail {
          height: 100%;
          max-height: 100px;
          width: 100%;
          object-fit: contain;
          padding: 0;
          background: #d4d4d4;
          border-radius: 3px;
        }
      }
      .slick-slide {
        padding: 0 5px;
        .img-thumbnail {
          border-radius: 3px;
        }
        &.slick-current {
          .img-thumbnail {
            border: solid 3px #fcbb55;
          }
        }
      }
    }

    .preview-carousel {
      height: calc(100% - 120px);
      border-bottom: solid 2px #f4f4f4;

      &.slick-slider {
        .slick-slide {
          > div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
          }
          .image-preview {
            display: flex !important;
            align-items: center;
            justify-content: center;
            height: 100%;
            img, video {
              width: 100%;
              height: 100%;
              max-width: 100%;
              max-height: 100%;
            }
          }
        }

      }
      .slick-arrow {
        width: 32px;
        height: 32px;
        &.slick-prev {
          left: -16px;
        }
        &.slick-next {
          right: -16px;
        }
        &.slick-prev, &.slick-next {
          &::before {
            font-size: 32px;
          }
        }
      }
    }

    /*.slick-slider {
      .slick-track {
        display: flex;
        align-items: center;
        height: 100%;
      }

      .slick-prev, .slick-next {
        background: #000;
        padding: 10px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        text-align: center;

        &::after {
          left: 50%;
          top: 50%;
        }
      }

      .slick-prev {
        left: -20px;

        &::after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }

      .slick-next {
        right: -20px;

        &::after {
          transform: translate(-50%, -50%) rotate(135deg);
        }
      }
    }

    .carousel-item {
      display: block;

      video {
        position: relative;
        left: auto;
        transform: none;
      }
    }*/
  }
}

.userprofile {
  display: block;
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;

  .panel {
    display: block;
    height: 100%;
    background: #d4d4d4;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.2);

    .panel-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 20px;
      border-radius: 10px 10px 0 0;
      border-bottom: solid 1px #f4f4f4;

      h5 {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 0;
      }

      .info {
        font-size: 20px;
        color: #4d556a;

        a {
          font-size: 14px;
          color: #4d556a;
        }
      }
    }

    .panel-content {
      display: block;
      padding: 20px;
    }
  }

  .swipe-button {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: right 0.3s ease-in-out;
    padding: 0;
    border: none;
    background: transparent;

    .icon-container {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      background: #001d3e;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
      color: white;
      border: none;
      padding: 10px;
      font-size: 16px;
      border-radius: 6px;

      .icon {
        width: 24px;
        min-width: 24px;
        height: 24px;
      }

    }

    .tooltip-text {
      visibility: hidden;
      background: #001d3e;
      color: white;
      text-align: center;
      padding: 8px 12px;
      border-radius: 6px;
      font-size: 14px;
      position: absolute;
      bottom: 120%;
      right: 0;
      white-space: nowrap;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);

      &::after {
        content: "";
        position: absolute;
        top: 100%;
        right: 20px;
        border-width: 6px;
        border-style: solid;
        border-color: #001d3e transparent transparent transparent;
      }
    }

    &:hover {
      width: auto;
      transition: width 5s;

      .tooltip-text {
        visibility: visible;
      }
    }
  }
}