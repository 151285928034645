[class*="mainHeader"] {
  .navbar {
    a.nav-brand {
      max-width: 172px;
      width: 100%;

      img {
        width: 100%;
      }
    }

    .nav-item {
      margin: 0;


      #collasible-nav-dropdown {
        line-height: 0;
        padding: 8px;
        border-radius: 50%;
        cursor: pointer;
        /*background-color: #f8fbff;*/
        background: #4d556a;

        svg {
          width: 25px;
          height: 25px;
          /*color: #575e70;*/
          color: #fff;
        }

        &::after {
          display: none;
        }

        &:hover {
          background-color: #fbb33a;
        }

        .ant-badge {
          position: absolute;
          right: 7px;
          top: 6px;
          transition: 0.5s;

          .ant-badge-dot {
            width: 10px;
            height: 10px;
            transition: 0.5s;
          }
        }
      }

      .dropdown-menu {
        margin-top: 10px;

        &[data-bs-popper] {
          left: auto;
          right: 0;
          width: 500%;
          padding: 1.75rem 1.5rem;
          border-radius: 1.5rem;
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
          min-width: 260px;
          border: 2px solid #e8e8e8;
        }
      }
    }
  }

  .line {
    border-color: #aeaeae;
    border-radius: 100%;
    margin: 1.5rem 0;
  }
}

.product-dropdown {
  .dropdown-menu {
    left: 0 !important;
    width: 100% !important;
    padding: 0.75rem 0.5rem !important;
    border-radius: 0.8rem !important;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1) !important;
    min-width: 201px !important;
    border: 2px solid #e8e8e8;
    background: #fff;
    right: auto !important;

    a {
      color: #212529;
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
      margin-bottom: 3px;

      &:hover {
        border-radius: 0.5rem;
        color: #212529;
        text-decoration: none;
      }

      &:active {
        background-color: #f8f9fa;
      }

      &.active {
        background-color: #f8f9fa;
      }
    }
  }
}

.dropdown-toggle {
  img {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 990px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    margin-right: 0 !important;
  }
}