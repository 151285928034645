[class*="propertyDetails"] {
  .accordion-button {
    &::after {
      width: 24px;
      height: 25px;
      background-size: contain;
    }
  }

  .accordion-body {
    .nav-link {
      // padding: 0 43px 16px 43px;
      // border: 0;
      // color: #7D8398;
      // background: transparent;
      border: 1px solid #fbb33a;
      color: #000000;
      margin-right: 10px;

      &:hover {

        border: 1px solid #fbb33a;
        color: #000000;
        background-color: #fbb33a33;
      }

      &.active {
        border-bottom: 2px solid #121212;
        color: #212529;
        background: #fbb33a38;
        border: 1px solid #fbb33a;
      }
    }
  }

  .accordion-header {
    button {
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      color: #313442;
      background: #E9ECF4;
      a {
        &.req-document-btn {
          position: absolute;
          right: 77px;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }

  .tabAccordion {
    .carousel {
      .carousel-control-prev {
        left: -53px;
        justify-content: left;
      }

      .carousel-control-next {
        right: -53px;
        justify-content: flex-end;
      }

      .carousel-indicators {
        margin-bottom: 0;
      }
    }
  }


  .duration {
    display: flex;
    justify-content: flex-end;

    span {
      font-weight: 400;
      font-size: 16px;
      color: #7D8398;

      &:first-child {
        margin-right: 0;
      }

      &:last-child {
        margin-left: 45px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .accordion-body {
      .nav-link {
        padding: 0 28px 16px 28px;
      }
    }
  }
}