[class*="addProductSection"] {
  input::placeholder {
    color: #bdbdbd;
    // font-size: 12px;
  }
// select {
//   font-size: 12px;
//   color: #bdbdbd;
//   option{
//     font-size: 16px;
//     color: #212529;
//   }
// }
}