[class*="testimonialWrapper"] {
    .carousel {
      .carousel-inner {
        min-height: 403px;
      }
      .carousel-indicators {
        bottom: -5rem !important;
        button {
          background-color: #d1d5db !important;
          width: 8px;
          height: 8px;
          border-radius: 100%;
          margin: 0 4px;
          &.active {
            background-color: #374151 !important;
          }
        }
      }
      .carousel-control-prev {
       display: none;
      }
      .carousel-control-next {
       display: none;
      }
    }
  }