[class*="amenityCanvas"] {
  // .ant-modal-content {
  //   .ant-modal-title {
  //     font-size: 1.25rem;
  //   }
  //   form {
  //     .addVisitorLable {
  //       font-weight: 500;
  //       margin-top: 0.5rem;
  //       display: block;
  //     }
  //     .ant-picker {
  //       height: 37.6px;
  //       width: 100%;
  //     }
  //   }
  //   .ant-modal-footer {
  //     text-align: center;
  //     display: flex;
  //     justify-content: center;
  //     .ant-btn {
  //       border: 0;
  //       border-radius: 3px;
  //       color: #fff;
  //       font-size: 16px;
  //       font-weight: 500;
  //       padding: 20px 26px;
  //       margin-top: 10px;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       margin: 0 auto;
  //       &.ant-btn-primary {
  //         background: #FCBB55;
  //         margin-inline-start: auto;
  //         margin-top: 10px;

  //       }
  //       &.ant-btn-default {
  //         display: none;
  //       }
  //     }
  //   }
  // }
  .offcanvas-header {
    padding-bottom: 0;
  }
  .offcanvas-title  {
    font-size: 1.2rem;
    font-weight: 600;
    color: #262a35;
  }
  figure {
    height: 286px;
    border: 1px solid #d6d6d6dd;
    border-radius: 1rem;
    padding: 3px;
    background-color: #d6d6d6dd;
    margin: 0;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
    }
}
h5 {
  font-weight: 600;
  font-size: 16px;
}
.amenity-description {
  font-size: 14px;
  line-height: 18px;
  color: #454545;
}
.amenity-booking-info {
  justify-content: space-between;
  .amenity-timings {
    ul {
      list-style: none;
      padding: 0;
      // display: flex;
      gap: 25px;
      margin-bottom: 0;
      li {
        font-size: 14px;
        color: #454545;
        strong {
          font-size: 15px;
        }
      }
    }
  }
  .booking-amount {
    margin-right: 12px;
    h5 {
      span {
        color: #22c55e;
        font-weight: 600;
        font-size: 20px;
        margin-left: 3px;
        display: block;
        margin-top: 0.5rem;
      }
    }
  }
}
.addVisitorLable {
  font-weight: 500;
  font-size: 14px;
  margin-top: 0.5rem;
  display: block;
  margin-bottom: 0.2rem;
  span {
    color: #ff0000;
  }
}
  .ant-picker {
    height: 37.6px;
    width: 100%;
    }
    .ant-space {
      width: 100%;
    }
}